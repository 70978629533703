<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <b
                  ><p class="mb-0 ml-2" style="font-size: 18px">
                    SOLICITUD LOGÍSTICA Y TRANSPORTE NACIONAL
                    <span
                      v-show="form.id !== null && form.id !== undefined"
                      class="badge"
                      :class="
                        form.estado == 1
                          ? 'badge-info'
                          : form.estado == 2
                          ? 'badge-success'
                          : form.estado == 3
                          ? 'badge-danger'
                          : form.estado == 4
                          ? 'badge-primary'
                          : form.estado == 5
                          ? 'bg-warning'
                          : ''
                      "
                    >
                      {{ form.nEstado }}
                    </span>
                  </p></b
                >
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Carga seca</li>
                  <li class="breadcrumb-item active">Nacionales</li>
                  <li class="breadcrumb-item active">Solicitudes</li>
                  <li class="breadcrumb-item active">
                    Formulario {{ accion }}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <a class="ir-arriba" @click="irArriba()">
          <span class="fa-stack">
            <i class="fa fa-circle fa-stack-2x"></i>
            <i class="fa fa-arrow-up fa-stack-1x fa-inverse"></i>
          </span>
        </a>
        <section class="content">
          <div class="container-fluid">
            <fieldset class="well card">
              <legend class="well-legend text-bold bg-frontera text-light">
                Formulario {{ accion }} Solicitudes
              </legend>
              <div class="row">
                <div class="col-sm-9">
                  <div class="row">
                    <!-- <div class="form-group col-md-3">
                      <label for="user">Usuario</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        id="user"
                        v-model="form.user.name"
                        :class="
                          $v.form.user.$invalid ? 'is-invalid' : 'is-valid'
                        "
                        disabled
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <label for="correo">Correo</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        id="correo"
                        v-model="form.user.email"
                        disabled
                      />
                    </div>
                    <div class="form-group col-md-2">
                      <label for="celular">Celular</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        id="celular"
                        v-model="form.user.cel"
                        disabled
                      />
                    </div> -->
                    <div class="form-group col-md-3">
                      <label for="fecha">Fecha requerimiento solicitud</label>
                      <input
                        type="date"
                        :min="hoy"
                        class="form-control form-control-sm"
                        id="fecha"
                        v-model="form.fecha"
                        :class="
                          $v.form.fecha.$invalid ? 'is-invalid' : 'is-valid'
                        "
                        :disabled="
                          form.estado == 2 ||
                            form.estado == 3 ||
                            form.estado == 4
                        "
                        @change="validaFecha()"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <label for="tipo_solicitud">Tipo Solicitud</label>
                      <select
                        id="tipo_solicitud"
                        class="form-control form-control-sm col-md-12"
                        v-model="form.tipo_solicitud"
                        :class="
                          $v.form.tipo_solicitud.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                        @change="modalEmergencia()"
                        :disabled="
                          form.estado == 2 ||
                            form.id ||
                            form.estado == 3 ||
                            form.estado == 4
                        "
                      >
                        <option value="">Seleccione...</option>
                        <option
                          v-for="tipo_solicitud in listasForms.tipos_solicitudes"
                          :key="tipo_solicitud.numeracion"
                          :value="tipo_solicitud.numeracion"
                        >
                          {{ tipo_solicitud.descripcion }}
                        </option>
                      </select>
                    </div>
                    <div
                      class="form-group col-md-3"
                      v-if="form.tipo_solicitud == 2"
                    >
                      <label for="tipo_emergencia">Tipo Emergencia</label>
                      <select
                        id="tipo_emergencia"
                        class="form-control form-control-sm col-md-12"
                        v-model="form.tipo_emergencia"
                        :class="
                          $v.form.tipo_emergencia.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                        @change="modalEmergencia2()"
                        :disabled="
                          form.estado == 2 ||
                            form.id ||
                            form.estado == 3 ||
                            form.estado == 4
                        "
                      >
                        <option value="">Seleccione...</option>
                        <option
                          v-for="tipo_emergencia in listasForms.tipos_emergencias"
                          :key="tipo_emergencia.numeracion"
                          :value="tipo_emergencia.numeracion"
                        >
                          {{ tipo_emergencia.descripcion }}
                        </option>
                      </select>
                    </div>
                    <div
                      class="form-group col-md-3"
                      v-if="form.tipo_emergencia == 2"
                    >
                      <label for="nombre_vice">Nombre Vicepresidente</label>
                      <select
                        id="nombre_vice"
                        class="form-control form-control-sm col-md-12"
                        v-model="form.viceprecidente_id"
                        :class="
                          $v.form.viceprecidente_id.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                        :disabled="
                          form.estado == 2 ||
                            form.id ||
                            form.estado == 3 ||
                            form.estado == 4
                        "
                      >
                        <option value="">Seleccione...</option>
                        <option
                          v-for="vicepreseidentes in listasForms.vicepreseidentes"
                          :key="vicepreseidentes.id"
                          :value="vicepreseidentes.id"
                        >
                          {{ vicepreseidentes.nombres }}
                        </option>
                      </select>
                    </div>
                    <div class="form-group col-md-12">
                      <div class="row">
                        <div class="form-group col-md-6">
                          <label for="observaciones">Observaciones</label>
                          <textarea
                            class="form-control form-control-sm"
                            v-model="form.observaciones"
                            :class="
                              $v.form.observaciones.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                            :disabled="
                              form.estado == 2 ||
                                form.estado == 3 ||
                                form.estado == 4
                            "
                          >
                          </textarea>
                        </div>
                        <div class="form-group col-md-6">
                          <label for="justificacion">Justificación</label>
                          <textarea
                            class="form-control form-control-sm"
                            id="justificacion"
                            v-model="form.justificacion"
                            :class="
                              $v.form.justificacion.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                            :disabled="
                              form.estado == 2 ||
                                form.estado == 3 ||
                                form.estado == 4
                            "
                          >
                          </textarea>
                        </div>
                      </div>
                      <div
                        class="form-group col-md-12"
                        v-if="form.tipo_emergencia"
                      >
                        <div class="row">
                          <div class="col-md-3"></div>
                          <div
                            class="col-md-6 text-center"
                            style="font-size: 12px"
                          >
                            <b>
                              "Su solicitud se gestionará en el menor tiempo
                              posible. Esto dependerá de la disponibilidad y
                              ubicación de los vehículos o equipos por parte de
                              los contratistas, los tiempos para este proceso
                              podran tomarse entre 6, 12, 18 ó 24 horas."
                            </b>
                          </div>
                          <div class="col-md-3"></div>
                        </div>

                        <div class="row p-5">
                          <div class="col-md-3"></div>
                          <div
                            class="col-md-6 text-center"
                            v-if="form.tipo_emergencia"
                          >
                            <label for="archivo_emergencia"
                              >Cargar certificado de solicitud de emergencia
                            </label>
                          </div>
                          <div class="col-md-4"></div>
                          <div
                            v-if="form.archivo_emergencia == null"
                            class="justify-content-center"
                          >
                            <input
                              type="file"
                              id="file"
                              ref="file"
                              class="form-control-file text-center"
                              accept=".pdf, .PDF, .jpg, .JPG, .png, .PNG"
                              @change="elegirDirectorio()"
                              :disabled="
                                form.estado == 2 ||
                                  form.id ||
                                  form.estado == 3 ||
                                  form.estado == 4
                              "
                            />
                          </div>

                          <div v-else>
                            <button
                              type="button"
                              class="btn btn-light col-md-2"
                              @click="getArchivo(form.archivo_emergencia)"
                              v-show="form.archivo_emergencia !== null"
                              :disabled="
                                form.estado == 2 ||
                                  form.estado == 3 ||
                                  form.estado == 4
                              "
                            >
                              <i class="fas fa-file-download"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="form-group col-md-3">
                      <button
                        v-if="form.tipo_solicitud == 2"
                        class="btn bg-primary"
                        v-show="!$v.form.$invalid"
                        @click="saveDocumento()"
                        :disabled="form.estado == 2"
                      >
                        <i class="fas fa-paper-plane"></i><br />Guardar
                      </button>
                      <button
                        class="btn bg-primary"
                        v-show="!$v.form.$invalid"
                        @click="save()"
                        :disabled="form.estado == 2"
                        v-else
                      >
                        <i class="fas fa-paper-plane"></i><br />Guardar
                      </button>
                      <button
                        class="btn bg-secondary ml-1"
                        @click="validacionVolver()"
                      >
                        <i class="fas fa-reply"></i><br />Volver
                      </button>
                    </div>
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="card card-outline card-navy col-md-12 m-2 p-2">
                    <div class="card-header pt-2 pb-2">
                      <h3 class="card-title">
                        <h6>
                          <i class="fas fa-users"></i>
                          Datos de Usuario
                        </h6>
                      </h3>
                    </div>
                    <div>
                      <div class="form-group">
                        <label for="user">Usuario</label>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          id="user"
                          v-model="form.user.name"
                          :class="
                            $v.form.user.$invalid ? 'is-invalid' : 'is-valid'
                          "
                          disabled
                        />
                      </div>
                      <div class="form-group">
                        <label for="correo">Correo</label>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          id="correo"
                          v-model="form.user.email"
                          disabled
                        />
                      </div>
                      <div class="form-group">
                        <label for="celular">Celular</label>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          id="celular"
                          v-model="form.user.cel"
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
            <fieldset v-show="form.id" class="well card">
              <legend class="well-legend text-bold bg-frontera text-light">
                Tipo Servicio
                <button
                  data-toggle="modal"
                  data-target="#modal-form-tipo-servicio"
                  type="button"
                  class="btn btn-sm bg-primary text-white float-right m-1"
                  style="cursor: pointer"
                  @click="abrirModal('Crear', null)"
                >
                  <i class="fas fa-plus"></i>
                </button>
              </legend>
              <div
                class="row"
                v-for="(tipoSer, index) in tipoServi"
                :key="tipoSer.id"
              >
                <div class="col-md-12 col-sm-12 col-xs-12">
                  <div class="card card-info card-outline collapsed-card">
                    <div class="card-header pt-2 pb-2">
                      <h3 class="card-title">
                        <i class="fas fa-clipboard-list"></i> Detalle
                        <span class="badge badge-info">
                          {{ index + 1 }}
                        </span>
                      </h3>
                      <div class="card-tools">
                        <button
                          type="button"
                          data-card-widget="collapse"
                          data-toggle="tooltip"
                          title="Collapse"
                          class="btn btn-tool"
                        >
                          <i class="fas fa-plus"></i>
                        </button>
                      </div>
                    </div>
                    <div class="card-body">
                      <!-- Rutas -->
                      <div class="card" v-if="tipoSer.ruta != undefined">
                        <div class="card-header card text-bold">
                          <div class="row">
                            <div class="col-md-12">
                              <h5 class="mb-0">
                                <span class="badge badge-info"> Rutas</span>
                              </h5>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12" v-show="tipoSer.ruta.length > 0">
                          <CsSolicitudesNacionalesRutas
                            ref="CsSolicitudesNacionalesRutas"
                            :indice="index"
                            :dato="tipoSer"
                          />
                        </div>
                      </div>
                      <!-- Tipo Vehículo -->
                      <div class="card" v-if="tipoSer != undefined">
                        <div class="card-header card text-bold">
                          <div class="row">
                            <div class="col-md-12">
                              <h5 class="mb-0">
                                <span class="badge badge-info">
                                  Tipo Vehículo</span
                                >
                              </h5>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12" v-show="tipoSer">
                          <CsSolicitudesNacionalesTipoVehiculo
                            ref="CsSolicitudesNacionalesTipoVehiculo"
                            :indice="index"
                            :dato="tipoSer"
                          />
                        </div>
                      </div>
                      <!-- Detalle Carga -->
                      <div class="card" v-if="tipoSer.det_carga != undefined">
                        <div class="card-header card text-bold">
                          <div class="row">
                            <div class="col-md-12">
                              <h5 class="mb-0">
                                <span class="badge badge-info">
                                  Detalle Carga
                                </span>
                              </h5>
                            </div>
                          </div>
                        </div>
                        <div
                          class="col-md-12"
                          v-show="tipoSer.det_carga.length > 0"
                        >
                          <CsSolicitudesNacionalesDetCarga
                            ref="CsSolicitudesNacionalesDetCarga"
                            :indice="index"
                            :dato="tipoSer"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
            <div v-if="tipoServi != undefined">
              <fieldset class="well card" v-show="tipoServi.length > 0">
                <legend class="well-legend text-bold bg-frontera text-light">
                  Cargos Contables
                </legend>
                <CsSolicitudesNacionalesPresupuesto
                  ref="CsSolicitudesNacionalesPresupuesto"
                />
              </fieldset>
            </div>
          </div>
          <CsSolicitudesNacionalesModal
            ref="CsSolicitudesNacionalesModal"
          ></CsSolicitudesNacionalesModal>

          <CsSolicitudesNacionalesImport
            ref="CsSolicitudesNacionalesImport"
          ></CsSolicitudesNacionalesImport>
          <div class="row justify-content-center">
            <div class="form-group">
              <button
                class="btn bg-frontera text-white"
                v-if="
                  form.id /* &&
                    $refs.CsSolicitudesNacionalesRutas.ruta_descargue == 1 &&
                    $refs.CsSolicitudesNacionalesRutas.ruta_cargue == 1 */
                "
                @click="saveTotal()"
                :disabled="
                  form.estado == 2 ||
                    form.estado == 3 ||
                    form.estado == 4 ||
                    btnDisabled
                "
              >
                <i
                  class="fas fa-paper-plane"
                  v-b-popover.hover.top="'Autorizada'"
                  title="La solicitud se encuentra autorizada"
                  v-if="form.estado == 2"
                ></i>
                <i
                  class="fas fa-paper-plane"
                  v-b-popover.hover.top="'Rechazada'"
                  title="La solicitud se encuentra rechazada"
                  v-else-if="form.estado == 3"
                ></i>
                <i
                  class="fas fa-paper-plane"
                  v-b-popover.hover.top="'Postulado'"
                  title="Ya se encuentran vehiculos postulados para esta solicitud"
                  v-else-if="form.estado == 4"
                ></i>
                <i class="fas fa-paper-plane" v-else></i>
                <div>Enviar</div>
              </button>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import Loading from "../../../../components/Loading";
import $ from "jquery";
import axios from "axios";
import CsSolicitudesNacionalesRutas from "./CsSolicitudesNacionalesRutas";
import CsSolicitudesNacionalesDetCarga from "./CsSolicitudesNacionalesDetCarga";
import CsSolicitudesNacionalesPresupuesto from "./CsSolicitudesNacionalesPresupuesto";
import CsSolicitudesNacionalesTipoVehiculo from "./CsSolicitudesNacionalesTipoVehiculo";
import CsSolicitudesNacionalesModal from "./CsSolicitudesNacionalesModal";
import CsSolicitudesNacionalesImport from "./CsSolicitudesNacionalesImport";
import moment from "moment";
import { VBPopover } from "bootstrap-vue";

$(document).ready(function() {
  $(window).scroll(function() {
    if ($(this).scrollTop() > 0) {
      $(".ir-arriba").slideDown(600);
    } else {
      $(".ir-arriba").slideUp(600);
    }
  });
});

export default {
  name: "CsSolicitudesNacionalesForm",
  directives: {
    "b-popover": VBPopover,
  },
  components: {
    Loading,
    CsSolicitudesNacionalesRutas,
    CsSolicitudesNacionalesDetCarga,
    CsSolicitudesNacionalesPresupuesto,
    CsSolicitudesNacionalesTipoVehiculo,
    CsSolicitudesNacionalesModal,
    CsSolicitudesNacionalesImport,
  },

  data() {
    return {
      cargando: false,
      user: {},
      rutas: [],
      tipoServi: [],
      file: null,
      accion: "",
      metodo: "",
      hoy: moment().format("YYYY-MM-DD"),
      btnDisabled: false,
      form: {
        id: null,
        user_id: null,
        user: {},
        tipo_solicitud: null,
        tipo_emergencia: null,
        viceprecidente_id: null,
        fecha: null,
        observaciones: null,
        justificacion: null,
        gerente_area_id: null,
        archivo_emergencia: null,
        get_archivo: null,
        estado: 1,
        nEstado: null,
      },
      listasForms: {
        tipos_solicitudes: [],
        tipos_emergencias: [],
        vicepreseidentes: [],
      },
      guardar: 0,
      message: "",
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
    };
  },

  validations() {
    let form = {};
    // Cuando es emergencia
    if (this.form.tipo_solicitud == 2) {
      // Si es emergencia extemporanea
      if (this.form.tipo_emergencia == 2) {
        form = {
          user: {
            required,
          },
          fecha: {
            required,
          },
          tipo_solicitud: {
            required,
          },
          tipo_emergencia: {
            required,
          },
          viceprecidente_id: {
            required,
          },
          get_archivo: {
            required,
          },
          observaciones: {
            required,
          },
          justificacion: {
            required,
          },
        };
      } else {
        form = {
          user: {
            required,
          },
          fecha: {
            required,
          },
          tipo_solicitud: {
            required,
          },
          tipo_emergencia: {
            required,
          },
          observaciones: {
            required,
          },
          justificacion: {
            required,
          },
        };
      }
    } else {
      // Cuando no es emergencia
      form = {
        user: {
          required,
        },
        fecha: {
          required,
        },
        tipo_solicitud: {
          required,
        },
        observaciones: {
          required,
        },
        justificacion: {
          required,
        },
      };
    }

    return {
      form: form,
    };
  },

  methods: {
    async getIndex() {
      this.cargando = true;
      this.accion = this.$route.params.accion;
      await this.getUser();
      if (this.$route.params.accion == "Editar") {
        this.metodo = "PUT";
        this.form = await this.$route.params.data_edit;
        this.form.user_id = await this.$route.params.data_edit.user.id;
        this.form.get_archivo = (await this.form.archivo_emergencia)
          ? true
          : null;
        await this.getServicio();
        await this.$refs.CsSolicitudesNacionalesPresupuesto.getCsContable();
      } else {
        this.metodo = "POST";
        this.form.user = await this.$store.state.user;
        this.form.user_id = await this.$store.state.user.id;
      }
      this.cargando = false;
    },

    irArriba() {
      $("body,html").animate({ scrollTop: "0px" }, 1000);
    },

    modalEmergencia() {
      this.form.tipo_emergencia = null;
      this.eliminarLink();
      if (this.form.tipo_solicitud == 2) {
        this.$swal({
          icon: "info",
          html: `<h4>Recuerde, si selecciona Emergencia Operacional, la Solicitud se enviará a la <strong>Vicepresidencia de Operaciones</strong>.</h4>
          <p style= "color: red">*Nota: Por favor adjuntar la declaración y aprobación de la emergencia de su vicepresidente si cuenta con ella, de lo contrario su solicitud continuará el curso de aprobación por este medio (kissflow).</p>
          <p>Recuerde que si cancela su solicitud por cualquier motivo se deberá informar inmediatamente, de no hacerlo, esto le generará un costo adicional debido al alistamiento y posibles movilizaciones de los equipos.</p>
          `,
          confirmButtonText: "Aceptar",
          timerProgressBar: true,
        });
      }
      this.validaFecha();
    },

    modalEmergencia2() {
      if (this.form.tipo_emergencia == 2) {
        this.$swal({
          icon: "info",
          html: `<h4>Es obligatorio adjuntar el soporte de aprobación por el vp correspondiente.</h4>`,
          confirmButtonText: "Aceptar",
          timerProgressBar: true,
        });
      }
    },

    validaFecha() {
      if (this.form.tipo_solicitud == 1 && this.form.fecha) {
        let fecha_hoy = moment(this.hoy);
        let fecha_solicitud = moment(this.form.fecha);
        if (fecha_solicitud.diff(fecha_hoy, "days") < 1) {
          this.form.fecha = null;
          this.$swal({
            icon: "error",
            title:
              "Las Solicitudes Normales deben hacerce con más de un día de antelación.",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    },

    async getTipoSolicitud() {
      await axios.get("/api/lista/127").then((response) => {
        this.listasForms.tipos_solicitudes = response.data;
      });
    },

    async getTipoEmergencia() {
      await axios.get("/api/lista/155").then((response) => {
        this.listasForms.tipos_emergencias = response.data;
      });
    },

    getVicepresidente() {
      const in_id = [163, 245];
      const params = {
        in_id,
      };
      axios
        .get("/api/admin/funcionarios/lista", {
          params,
        })
        .then((response) => {
          this.listasForms.vicepreseidentes = response.data;
        });
    },

    async save() {
      if (this.form.id !== null && this.form.id !== undefined) {
        this.form.estado = 1;
      } else {
        this.form.estado = 5;
      }
      if (!this.$v.form.$invalid) {
        this.cargando = true;
        await axios({
          method: this.metodo,
          url: "/api/funcionariosFrontera/solicitudesNacionales",
          data: this.form,
        })
          .then((response) => {
            this.form.id = response.data.id;
            this.form.nEstado = response.data.nEstado;
            this.$route.params.accion = "Editar";
            this.getIndex();
            this.$route.params.data_edit = response.data;
            this.$swal({
              icon: "success",
              title: "Se guardo la solicitud Nacional...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.cargando = false;
          })
          .catch((e) => {
            this.cargando = false;
            this.$swal({
              icon: "error",
              title:
                "Ha ocurrido un error, por favor intente la acción nuevamente.",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    async saveDocumento() {
      this.cargando = true;
      var vm = this;
      let formData = new FormData();
      if (this.form.id !== null && this.form.id !== undefined) {
        formData.append("id", this.form.id);
      }
      formData.append("archivo_emergencia", vm.file);
      formData.append("user_id", vm.form.user_id);
      formData.append("tipo_solicitud", vm.form.tipo_solicitud);
      formData.append("tipo_emergencia", vm.form.tipo_emergencia);
      formData.append("fecha", vm.form.fecha);
      formData.append("justificacion", vm.form.justificacion);
      formData.append("observaciones", vm.form.observaciones);
      formData.append("estado", vm.form.estado);
      await axios
        .post(
          "/api/funcionariosFrontera/solicitudesNacionales/archivo_emergencia",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          this.form.id = response.data.id;
          this.form.estado = 1;
          this.getIndex();
          this.cargando = false;
          this.$swal({
            icon: "success",
            title: "Se guardó exitosamente...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error, vuelva a intentarlo... " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    async saveTotal() {
      if (this.tipoServi.length == 0) {
        this.cargando = false;
        await this.$swal({
          icon: "warning",
          title: "Advertencia!!",
          text:
            "No ha ingresado los datos del Tipo de Vehículo. Por favor validar ",
          timer: 3000,
          backdrop: `rgba(0,0,123,0.4) left top no-repeat`,
          timerProgressBar: true,
          confirmButtonText: "Aceptar",
        });
      } else if (
        this.$refs.CsSolicitudesNacionalesPresupuesto.csContables.length == 0
      ) {
        this.cargando = false;
        await this.$swal({
          icon: "warning",
          title: "Advertencia!!",
          text:
            "No ha ingresado los datos de Cargos Contables para esta solicitud. Por favor validar",
          timer: 3000,
          backdrop: `rgba(0,0,123,0.4) left top no-repeat`,
          timerProgressBar: true,
          confirmButtonText: "Aceptar",
        });
      } else if (
        this.$refs.CsSolicitudesNacionalesPresupuesto.totalPresupuesto < 100
      ) {
        this.cargando = false;
        await this.$swal({
          icon: "warning",
          title: "Advertencia!!",
          text:
            "El porcentaje total para Cecos y Afes debe ser del 100%. Por favor validar",
          timer: 3000,
          backdrop: `rgba(0,0,123,0.4) left top no-repeat`,
          timerProgressBar: true,
          confirmButtonText: "Aceptar",
        });
      } else {
        this.btnDisabled = true;
        await this.$refs.CsSolicitudesNacionalesPresupuesto.saveContableTotal();

        // Se valida si la solicitud queda automáticamente autorizada
        if (
          (this.form.tipo_solicitud == 2 && this.form.tipo_emergencia == 2) ||
          (this.form.tipo_solicitud == 2 &&
            this.form.tipo_emergencia == 1 &&
            this.form.get_archivo)
        ) {
          this.form.estado = 2;
        }
        await this.save();
        //await this.back();
      }
    },

    async getUser() {
      let me = this;
      await axios.get("/api/funcionariosFrontera/getUser").then((response) => {
        me.user = response.data[0];
      });
      me.form.user = await me.user;
      me.form.user_id = await me.user.id;
      me.form.nombre = await me.user.name;
      me.form.identificacion = await me.user.n_document;
      me.form.celular = await me.user.cel;
      me.form.area = (await me.user.funcionario[0])
        ? me.user.funcionario[0].nArea
        : "";
    },

    back() {
      return this.$router.replace("/CargaSeca/SolicitudesNacionales");
    },

    elegirDirectorio() {
      this.file = null;
      this.form.get_archivo = null;
      if (this.$refs.file.files[0]) {
        this.file = this.$refs.file.files[0];
        this.form.get_archivo = true;
      }
    },

    eliminarLink() {
      this.file = null;
      this.form.archivo_emergencia = null;
      this.form.get_archivo = null;
    },

    getArchivo(url) {
      window.open(this.uri_docs + url, "_blank");
    },

    abrirModal(accion, idTipo) {
      this.$refs.CsSolicitudesNacionalesModal.init(accion, idTipo);
    },

    validacionVolver() {
      this.$swal({
        title: "¿Está seguro de realizar esta acción?",
        text:
          "Por favor validar nuevamente si desea salir de esta pantalla, ya que en caso de aceptar perdera todo lo anteriormente diligenciado. Le recomendamos verificar si desea salir realmente de esta pantalla! En caso de que si oprima aceptar, en caso de que no oprima cancelar.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Aceptar!",
      }).then((result) => {
        if (result.value) {
          this.back();
        }
      });
    },

    async getServicio() {
      await axios
        .get("/api/funcionariosFrontera/solicitudesNacionalesServicio", {
          params: { solicitudNacional_id: this.form.id },
        })
        .then(async (response) => {
          this.tipoServi = response.data;
          for (let i = 0; i < this.tipoServi.length; i++) {
            for (let j = 0; j < this.tipoServi[i].det_carga.length; j++) {
              let carga = this.tipoServi[i].det_carga[j];
              carga.medidas =
                carga.largo + "X" + carga.ancho + "X" + carga.alto;
            }
          }
        });
    },
  },

  async mounted() {
    await this.getIndex();
    await this.getTipoSolicitud();
    await this.getTipoEmergencia();
    await this.getVicepresidente();
  },
};
</script>

<style>
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

.well {
  min-height: 20px;
  padding: 14px;
  margin-bottom: 10px;
  border: 2px solid #001871;
  border-radius: 5px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}

.well-legend {
  display: block;
  font-size: 20px;
  width: auto;
  min-width: 300px;
  padding: 2px 7px 2px 5px;
  line-height: unset;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}

.ir-arriba {
  display: none;
  background-repeat: no-repeat;
  font-size: 20px;
  color: black;
  cursor: pointer;
  position: fixed;
  bottom: 80px;
  right: 8px;
  z-index: 2;
}
</style>
